import React, { useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useFormik } from 'formik'

import SEO from '../components/atoms/Seo'
import Navbar from '../components/organisms/Navbar'
import FormItems from '../components/atoms/FormItems'
import SubmitButton from '../components/atoms/SubmitButton'
import ModalContainer from '../components/atoms/ModalContainer'
import HomeSectionTitle from '../components/atoms/HomeSectionTitle'
import getSearchFromLocation from '../utils/params'
import HomeSectionDescription from '../components/atoms/HomeSectionDescription'
import { PHONE_REGEXP } from '../config'
import { useFetchMeQuery } from '../queries/users'
import * as Yup from 'yup'

const DESCRIPTION = 'Contactez l’équipe de TrouverMonLocalPro pour une question ou un devis.'
const ZAPIER = 'https://hooks.zapier.com/hooks/catch/2114713/3c046g6/'

const formSchema = Yup.object().shape({
  phone: Yup.string().required('Votre numéro de téléphone est requis').matches(PHONE_REGEXP, 'Votre téléphone n\'est pas au bon format'),
  email: Yup.string().email('Votre email n\'est pas au bon format').required('Votre email est requis'),
  subject: Yup.string().required('Quel est le sujet de votre message ?'),
  message: Yup.string().required('Un message est requis'),
  lastname: Yup.string().required('Votre nom est requis'),
  firstname: Yup.string().required('Votre prénom est requis'),
})

const items = [{
  placeholder: 'Nom',
  type: 'text',
  name: 'lastname'
}, {
  placeholder: 'Prénom',
  type: 'text',
  name: 'firstname'
}, {
  placeholder: 'Numéro de téléphone',
  type: 'text',
  name: 'phone'
}, {
  placeholder: 'Email',
  type: 'email',
  name: 'email'
}, {
  placeholder: 'Sujet du message',
  type: 'text',
  name: 'subject'
}, {
  placeholder: 'Dites-nous comment nous pouvons vous aider',
  type: 'textarea',
  name: 'message'
}]

const Title = styled(HomeSectionTitle)`
  margin-bottom: 16px;
`
const Submit = styled(SubmitButton)`
  margin-top: 12px;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

const NousContacterForm = ({ user, subject }) => {
  const [send, setSend] = useState(false)

  const { values, handleSubmit, handleChange, setFieldValue, errors, touched, handleBlur } = useFormik({
    initialValues: {
      email: get(user, 'email', ''),
      phone: get(user, 'phone', ''),
      lastname: get(user, 'lastname', ''),
      firstname: get(user, 'firstname', ''),
      subject: subject,
      message: ''
    },
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async values => {
      try {
        const time = Math.floor(Date.now() / 1000)

        await axios.get(ZAPIER, {
          params: {
            ...values,
            time
          }
        })

        setSend(true)
      } catch (e) {
      }
    }
  })

  return (
    <>
      <Title tag='h2' bottom={16}>Nous contacter</Title>
      {!send && <Form onSubmit={handleSubmit}>
        <FormItems
          width={true}
          items={items}
          errors={errors}
          values={values}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue} />
        <ButtonContent>
          <Submit type='submit'>Envoyer</Submit>
        </ButtonContent>
      </Form>}
      {send && <HomeSectionDescription>Votre message a bien été envoyé !</HomeSectionDescription>}
    </>
  )
}

const NousContacter = ({ location }) => {
  const { data, error } = useFetchMeQuery(false)
  const params = getSearchFromLocation()

  return (
    <>
      <SEO
        title='Nous contacter'
        location={location}
        description={DESCRIPTION} />
      <Navbar location={location} />
      <ModalContainer
        top='62'
        shadow={true}
        size='500px'>
        <NousContacterForm
          user={get(data, 'user', {})}
          subject={get(params, 'subject', '')} />
      </ModalContainer>
    </>
  )
}
NousContacter.propTypes = {
  location: PropTypes.object
}

export default NousContacter
